<template>
  <div class="maintenanceFields">
    <v-menu
      v-model="datpickerOpen"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="maintenance.created"
          name="created"
          label="Datum"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="maintenance.created"
        @input="datpickerOpen = false"
        :first-day-of-week="1"
        :rules="dateRule"
        locale="de-de"
        :show-current="true"
      ></v-date-picker>
    </v-menu>

    <v-textarea
      name="note"
      v-model="maintenance.note"
      :rules="noteRule"
      label="Notiz"
      rows="3"
      class="mb-3"
      row-height="15"
    ></v-textarea>
  </div>
</template>

<script>
export default {
  name: "maintenanceFields",
  props: ["maintenance"],
  data: () => ({
    datpickerOpen: false,
    dateRule: [(v) => !!v || "Bitte Datum angeben"],
    noteRule: [
      (v) => !!v || "Bitte Wartung beschreiben",
      (v) => (v && v.length > 10) || "Bitte Wartung besser beschreiben",
    ],
  }),
  methods: {},
  mounted() {},
};
</script>
